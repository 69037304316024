<template>
  <div v-if="showAddToCart == true">
  <div class="addtocart-area" v-if="get_pub_date==true">
    <div class="addtocart-bar">
      <!-- <label for="quantity435423" class="quantity_box" :class="getInputPlaceClass()">Quantity:</label> -->
    
       <div v-if="(host!='acc')&&(host!='allenandunwinnz')">
         
      <span class="vm-quantity-box quantity_box">
        <input
          type="text"
          class="quantity-input js-recalculate"
          :class="getInputPlaceClass()"
          name="quantity[]"
          v-model="qty"
        />
      <span class="ship-eta" v-if="place != 'search' && getShipETA">{{getShipETA}}</span>
      </span>
      <span class="vm-quantity-controls js-recalculate">
      </span>
    </div>
      <button
        name="addtocart"
        class="addtocart-button button button--color1 button--fullwidth"
        :class='getAddToCartClass()'
        value="Add to Cart"
        title="Add to Cart"
        @click="addProductToCart"
      >Add to Cart</button>
      <span class="ship-eta-search" v-if="place == 'search' && getShipETA">{{getShipETA}}</span>

      <div class="clear"></div>
    </div>

    <div class="clear"></div>
    <!-- <v-dialog /> -->
    <modals-container />
  </div>
  <div class="addtocart-area" v-else>
    <div class="addtocart-bar" v-if="host=='acc'">
      <button
        name="addtocart"
        class="addtocart-button button button--color1 button--fullwidth"
        :class='getAddToCartClass()'
        value="Add to Cart"
        title="Add to Cart"
        @click="addProductToCart"
      >Add to Cart</button>
      <span class="ship-eta-search" v-if="place == 'search' && getShipETA">{{getShipETA}}</span>

      <div class="clear"></div>
    </div>
    <div class="addtocart-bar" v-else>
      <button v-if="pre_order_button"
          name="addtocart"
          class="addtocart-button button button--color1 button--fullwidth"
          :class='getAddToCartClass()'
          value="Add to Cart"
          title="Add to Cart"
          @click="addProductToCart"
        >Pre-order</button>
      <button v-else
          name="addtocart"
          class="addtocart-button button button--color1 button--fullwidth"
          :class='getAddToCartClass()'
          value="Add to Cart"
          title="Add to Cart"
          @click="addProductToCart"
        >Add to Cart</button>
      <span class="ship-eta-search" v-if="place == 'search' && getShipETA">{{getShipETA}}</span>

      <div class="clear"></div>
    </div>

    <div class="clear"></div>
    <!-- <v-dialog /> -->
    <modals-container />
  </div>
  <div class="addtocart-area" v-else>
    <div class="addtocart-bar">
      <!-- <label for="quantity435423" class="quantity_box" :class="getInputPlaceClass()">Quantity:</label> -->
      <span class="vm-quantity-box quantity_box">
        <input
          type="text"
          class="quantity-input js-recalculate"
          :class="getInputPlaceClass()"
          name="quantity[]"
          v-model="qty"
        />
      <span class="ship-eta" v-if="place != 'search'">{{getShipETA}}</span>
      </span>
      <span class="vm-quantity-controls js-recalculate">
        <!-- <button
          type="button"
          class="quantity-controls quantity-plus image-button button--color2 icon--plus"
          @click="qty += 1"
        />
        <button
          type="button"
          class="quantity-controls quantity-minus image-button button--color2 icon--minus"
          @click="removeQty"
        />-->
      </span>
      <button v-if="get_pub_date < get_now_date"
        name="addtocart"
        class="addtocart-button button button--color1 button--fullwidth"
        :class='getAddToCartClass()'
        value="Add to Cart"
        title="Add to Cart"
        @click="addProductToCart"
      >Pre-order</button>
      <span class="ship-eta-search" v-if="place == 'search'">{{getShipETA}}</span>

      <div class="clear"></div>
    </div>

    <div class="clear"></div>
    <!-- <v-dialog /> -->
    <modals-container />
    </div>
  </div>
  <div class="addtocart-area" v-else>
    <div v-if="host!='allenandunwinnz'">
      <div class="addtocart-bar">
        <!-- <label for="quantity435423" class="quantity_box" :class="getInputPlaceClass()">Quantity:</label> -->
        <span class="vm-quantity-box quantity_box" v-if="get_pub_date < get_now_date">
          <input
            type="text"
            class="quantity-input js-recalculate"
            :class="getInputPlaceClass()"
            name="quantity[]"
            v-model="qty"
          />
        <span class="ship-eta" v-if="place != 'search'">{{getShipETA}}</span>
        </span>
        <span class="vm-quantity-controls js-recalculate">
          <!-- <button
            type="button"
            class="quantity-controls quantity-plus image-button button--color2 icon--plus"
            @click="qty += 1"
          />
          <button
            type="button"
            class="quantity-controls quantity-minus image-button button--color2 icon--minus"
            @click="removeQty"
          />-->
        </span>
        <button v-if="get_pub_date < get_now_date"
          name="addtocart"
          class="addtocart-button button button--color1 button--fullwidth"
          :class='getAddToCartClass()'
          value="Add to Cart"
          title="Add to Cart"
          @click="addProductToCart"
        >Pre-order</button>
        <span class="ship-eta-search" v-if="place == 'search'">{{getShipETA}}</span>

        <div class="clear"></div>
      </div>

    <div class="clear"></div>
    <!-- <v-dialog /> -->
    <modals-container />
    </div>
  </div>
</template>

<script>

export default {
  name: "add-to-cart",
  props: ["isbn", "title", "place", 'book'],
  data() {
    console.log($('.value-text').text(),'======cart val')
    return {
      qty: 1,
      host : this.$store.state.site_data.host,
    }
  },
  methods: {
    pre_order_button(){
      var test = 'uk';
      if  ((document.location.hostname).search(test) != -1 ){
        var pubDate = this.book.book.uk_pub_date;
      }
      else{
        var pubDate = this.book.book.pubdate;
      }
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
      var pub_date = Date.parse(pubDate);
      var current_date = Date.parse(date);
      if (pub_date > current_date) {
          return true
      }
      else{
       return false
      }
    },
    addProductToCart() {
      let self = this
      this.$store
        .dispatch("addProduct", {
          isbn: this.isbn,
          qty: this.qty
        })
        .then(function(data) {
          self.$modal.show(
            {
              template: `
                <div class="modal-cont-body">
                  <div class="dialog-content"> 
                    <div class="dialog-c-title">${self.qty} X ${self.title.replace('null',"").replace('undefined',"")}</div>
                    <div class="dialog-c-text">Added to Cart</div>
                  </div>
                  <div class="vue-dialog-buttons">
                    <button class="vue-dialog-button" style="flex: 1 1 80%;" @click="$router.push('/cart'), $emit('close')">Show Cart</button>
                    <button @click="$emit('close')" class="vue-dialog-button" style="flex: 1 1 100%;">Continue Shopping</button>
                  </div>
                </div>
              `,
            },
          )
        })
    },
    removeQty() {
      if (this.qty > 1) {
        this.qty -= 1
      }
    },
    getInputPlaceClass() {
      if (this.place == "search") {
        return "search-qty-input"
      }
      return ""
    },
    getAddToCartClass(){
      if(this.place == 'search'){
        return 'search-add-to-cart'
      }
      return ''
    },
     daysBetween(startDate, endDate) {
      var millisecondsPerDay = 24 * 60 * 60 * 1000
      return Math.round((endDate - startDate) / millisecondsPerDay)
  }
  },
  computed: {
    showAddToCart() {
      let stock_date = null
      if(this.book.book){
        stock_date = new Date(this.book.book.stock_date)
      }else{
        stock_date = new Date(this.book.stock_date)
      }
      if(this.host=='acc'){
      if(this.$store.state.site_data.ecommerce){
        let current_date = new Date()
        let stock_date = new Date(this.book.stock_availability_date)
        let date_plus_4_months = new Date(current_date.setMonth(current_date.getMonth()+4))

        if(stock_date>date_plus_4_months){
          return false
        }
        return true
      }
      return false
      }
      else{
        if(this.book.stock_availability_date && this.book.stock_availability_date != '0000-00-00' && this.$store.state.site_data.ecommerce){
        let current_date = new Date()
        // let stock_date = new Date(this.book.stock_availability_date)
        let date_plus_4_months = new Date(current_date.setMonth(current_date.getMonth()+4))

        if(stock_date>date_plus_4_months){
          return false
        }
        return true
      }
      return false
      }
    },
    get_pub_date() {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
      let pubdate = null
      if(this.book.book){
        pubdate = new Date(this.book.book.pubdate)
      }else{
        pubdate = new Date(this.book.pubdate)
      }
      const dateObj = new Date(pubdate)
      let aus_d = dateObj.toLocaleString("en-US", {timeZone: "Australia/Sydney"});

      const dateObj1 = new Date()
      let aus_d2 = dateObj1.toLocaleString("en-US", {timeZone: "Australia/Sydney"});

      const aus_pub_date = aus_d.split(',');
      const aus_current_date = aus_d2.split(',');

      let aus_parsed_current_date = Date.parse(aus_current_date[0]);
      let aus_parsed_pub_date = Date.parse(aus_pub_date[0]);

      var status = false
      if (aus_parsed_pub_date <= aus_parsed_current_date){
        status = true 
      }
      else{
          status = false    
      }
      return status
    },

    getShipETA(){
      let stock_date = new Date(this.book.stock_availability_date)
      let current_date = new Date()
      let date_diff = this.daysBetween(current_date, stock_date)
      let pubdate = null
      if(this.book.book){
        pubdate = new Date(this.book.book.pubdate)
      }else{
        pubdate = new Date(this.book.pubdate)
      }

      if(stock_date <= pubdate){
        if(this.book.stock_availability_date && this.book.stock_availability_date != '0000-00-00' && this.$store.state.site_data.ecommerce){
          let format_date = new Date(this.book.stock_availability_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          );
          return `Ships on ${format_date}`
        }else{
          return ''
        }
      }
      else if(date_diff<=5){
        return 'Ships within 5 days'
      }else if(date_diff<=7){
        return 'Ships within 5-7 days'
      }else if(date_diff<14){
        return 'Ships in 1-2 weeks'
      }else if(date_diff<21){
        return 'Ships in 2-3 weeks'
      }else if(date_diff<28){
        return 'Ships in 3-4 weeks'
      }else if(date_diff<30){
        return 'Ships in 1 month'
      }else if(date_diff<60){
        return 'Ships in 1-2 months'
      }else if(date_diff<90){
        return 'Ships in 2-3 months'
      }else if(date_diff<120){
        return 'Ships in 4 months'
      }
      else{
        return ''
      }
    }
  }
}
</script>

<style>
.quantity_box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.quantity-input {
  width: 60% !important;
  float: right;
  margin-bottom: 0px !important;
}
.search-qty-input {
  height: 33px !important;
  display: none !important;
}
.search-add-to-cart {
  height: 50px !important;
  font-size: 16px !important;
}
.addtocart-button{
  margin-top: 20px;
}
.ship-eta-search{
    text-align: center;
    font-size: 14px;
    margin-top: 14px;
    color: #6b6b6b;
    flex: 0 0 120px;
}
.ship-eta{
    text-align: center;
    font-size: 16px;
    color: #6b6b6b;
    flex: 0 0 120px;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .search-add-to-cart {
    font-size: 15px !important;
    background-position: 90% center !important;
    padding-left: 0.6em;
  }
}
/*.v--modal-box.v--modal {
    width: 415px !important;
    height: auto !important;
    left:0 !important;
    margin: 0 auto;
}*/

.v--modal-box.v--modal {
    background: none;
    box-shadow: none;
}
.modal-cont-body {
    width: fit-content ;
    height: auto;
    margin: 0 auto;
    min-width: 415px;
    max-width: 600px;
    background: #fff;
}
.dialog-content {
    flex: 1 0 auto;
    padding: 15px;
    font-size: 14px;
    /* width: 100%; */
}
.dialog-c-title {
    font-weight: 600;
    padding-bottom: 15px;
}
 .vue-dialog-buttons {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    border-top: 1px solid #eee;
}
.show-cart-button{
  color: inherit;
  font-weight: inherit;
}
.addtocart-area .v--modal {
  max-width: 34vw;
  max-height: 150px;
}
</style>