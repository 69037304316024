<template>
  <footer class="l-footer mobileFullWidthWithPadding">
    <div class="wrapper">
       <div class="col-inline">
          <div class="moduletable footer-menu">
            <cookie-law>
                  <div slot-scope="props" style="display:flex; justify-content:space-between;width:100%">
                     <div class="cookie-text">
                     <p>
                       By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation,
                     </p>
                     <p> 
                        analyze site usage, and assist in our marketing efforts.
                     </p>
                     </div>
                  <div>
                     <button type="button" class="button button-cookie" @click="showSidebar"
                     ><span>Manage Cookies</span></button>
                     <button class="button button-cookie" @click="props.accept"><span>I accept</span></button>
                   
                  </div>
                     <button class="cancel-button" @click="props.close"><span>x</span></button>
                  </div>
            </cookie-law>

            <vue-over-body :dim="dim" :open="sidebar" before="sideBefore" after="sideAfter">
            <div style="padding:10px;background-color:#f8f8f8;min-height:100%">
               <aside class="menu">
                  <div class="side-r">
                     <p class="menu-label">
                        <img v-if="host=='murdoch'" src="/static/logo_murdoch.svg" alt="logo">
                        <img v-else src="/static/logo_uk_murdoch.svg" alt="logo">
                     </p>
                  </div>
                  <div class="ot-pc-content">
                     <h2>Privacy Preference Center</h2>
                     <div>
                        Before we start: How we process your data
                        We use cookles on our retail website, flyer system and recipe site to provide you with the best
                        possible experience. This includes data processing that helps our website run smoothly, as well as
                        other techniques which ensure the content you're seeing is relevant to you.
                        You can click 'Accept all' to accept all cookles, or specifically select In the "Detalled selection*
                        section, which cookles you would like to allow. Further Information Including your right to yithdram
                        consent at any time, can be found in our privacy policy. You can find out more by clicking here.
                        <br>
                        <a href="https://cookiepedia.co.uk/giving-consent-to-cookies" class="privacy-notice-link __web-inspector-hide-shortcut__" rel="noopener" target="_blank" aria-label="More information about your privacy, opens in a new tab" tabindex="0">More information</a>
                     </div>
                  </div>
                  <div class="bottom-bt">
                      <button class="button button-cookie" @click="sidebar = 0"><span>Reject All</span></button>
                     <button class="button button-cookie"   @click="sidebar = 0"><span>Accept All Cookies</span></button>
                  </div>
                  <div>
                  </div>
                  </aside>
               </div>
            </vue-over-body>

             <footer-menu></footer-menu>
          </div>
          <!-- <div class="moduletable subscribe mobileFullWidth">
             <h3>Subscribe to newsletter</h3>
             <div class="custom subscribe mobileFullWidth">
                <p>
                   <input
                      name="cm-ydalki-ydalki"
                      required
                      type="email"
                      v-model="newsletterEmail"
                      id="subscribe-to-newsletter"
                      class="js-cm-email-input"
                      />
                </p>
                <p>
                   <button type="submit" @click="goToSubscribePage" class="button button--color2">Subscribe</button>
                </p>
             </div>
          </div> -->
          <div></div>
            <div v-if="(host=='murdochuk')">
               <div class="custom social-icons mobileFullWidthWithPadding social-center" >
                  <p class="socail_media">
                  <a class="icon-t" href="https://twitter.com/MurdochBooksUK" target="_blank">
                     <span>Twitter</span>
                  </a>
                  <a class="icon-y" href="http://www.youtube.com/user/MurdochBooks" target="_blank">
                  <span>Youtube</span>
                  </a>
                  <a class="icon-i" href="https://www.instagram.com/murdochbooks_uk/" target="_blank">
                     <span>Instagram</span>
                  </a>
                  </p>
               </div>
            </div>
             <div v-else>
               <div class="custom social-icons mobileFullWidthWithPadding social-center" >
                  <p class="socail_media">
                  <a class="icon-t" href="https://twitter.com/murdochbooks" target="_blank">
                     <span>Twitter</span>
                  </a>
                  <a class="icon-y" href="http://www.youtube.com/user/MurdochBooks" target="_blank">
                  <span>Youtube</span>
                  </a>
                  <a class="icon-i" href="https://www.instagram.com/murdochbooks/" target="_blank">
                     <span>Instagram</span>
                  </a>
                  <a class="icon-f" href="https://www.facebook.com/MurdochBooks" target="_blank">
                     <span>Facebook</span>
                  </a>
                  </p>
               </div>
            </div>
       </div>
       <div class="clear"></div>
    </div>
  </footer>
</template>

<script>
import FooterMenu from "../components/FooterMenu";
import newsLetterMixin from '../mixins/Newsletter';
import CookieLaw from 'vue-cookie-law';
import overBody from 'vue-over-body'

export default {
  name: "murdoch-footer",
  components: { 
      FooterMenu,
      CookieLaw,
      'vue-over-body': overBody
  },
  data() {
    return {
      index: 1,
      modal: 0,
      sidebar: 0,
      notification: 0,
      dim: true,
      host : this.$store.state.site_data.host,
    }
    },
   methods: {
      showSidebar: function () {
        this.$data.sidebar = this.$data.index
        this.$data.index += 2
      },
      },
  mixins: [newsLetterMixin],
}
</script>

<style>
.footer-menu a {
  font-size: 0.929em !important; 
}
.Cookie--base {
    background: #F1F1F1;
    color: #232323;
}
.Cookie--batman {
  background: black;
}
  .Cookie__button {
      background: black;
      color: yellow;
  }

  .Cookie__message {
    color: yellow;
  }
  .button-cookie{
    padding: 9px;
    margin: 12px;
    background-color: #004c6f;
    color: #F1F1F1;
}
.p-tag{
   width: 770px;
   color:#004c6f;
}
.cookie-text p{
   margin:0;
}
.cookie-text{
   padding: 10px 0px 0px 26px;
}
.cancel-button{
       border: none;
    font-size: 20px;
    font-weight: 800;
}

.sideBefore {
      height: 100%;
      width: 322px;
      top: 0;
      left: -300px;
    }

.sideAfter {
      left: 0;
}

.side-r{
   border-bottom: 1px solid #e9e9e9;
    margin: 11px;
}
.bottom-bt{
   display: inline-flex;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

@media screen and (max-width:767px) {
   .socail_media{
      width: 180px;
      font-size: 12px;
   }
}
</style>